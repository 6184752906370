.loader {
  position: relative;
  overflow: hidden;
}

.loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgb(217, 217, 217), transparent);
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
