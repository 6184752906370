body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  appearance: none;
} 

::-webkit-scrollbar {
  width: .4rem;
  height: .4rem;
}

::-webkit-scrollbar-thumb {
  border-radius: .2rem;
  background-color: #00000066;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

@media (max-width: 1023px) {
  html {
    font-size: 14px;
  }
}
