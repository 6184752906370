.range::-webkit-slider-thumb {
  pointer-events: auto;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #000;
}

.inputNumber::-webkit-inner-spin-button,
.inputNumber::-webkit-outer-spin-button {
  appearance: none;
}